:root {
  --map-spot-bookeable-with-schedules-fill: var(--map-available-color);
  --map-spot-fully-available-fill: var(--map-available-color);
  --map-spot-not-assigned-stroke: var(--map-available-color);
  --map-spot-selected-stroke: var(--color-primary);
}

.interactive-map-container {
  margin: 0 auto;
  padding: 40px;
}

/* TODO: The padding bottom here creates the overflow scroll on the map.
   Need to find a way to have the map be centered like we want and resizable
   without having a massing padding block that creates the height on the page.
*/
#map {
  display: inline-block;
  position: relative;
  width: 100%;
  vertical-align: top;
}



svg.interactive-zone-svg.chrome96Bug text {
  /*@todo chrome96Bug*/
  display: none;
}

#map svg {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto 0 auto;
  /**
     * max-(width|height) 100%/80vh ensures that maps
     * reports the correct WxH in viewport beyond
     * the attributes width x height set in svg
     * Don't remove this without trying map search
     */
  max-width: 100%;
  max-height: 80vh;
}

#map .js-map.js-map-perspective {
  transform: perspective(300px) rotateX(20deg);
}

#map .js-map.js-map-flat {
  transform: perspective(0px) rotateX(0deg);
}

#map .js-map.js-map-up {
  transform: rotateZ(0deg);
}

#map .js-map.js-map-down {
  transform: rotateZ(180deg);
}

#map .js-map.js-map-left {
  transform: rotateZ(-90deg);
}

#map .js-map.js-map-right {
  transform: rotateZ(90deg);
}

#map .js-map.js-map-perspective.js-map-up {
  transform: perspective(300px) rotateX(20deg) rotateZ(0deg);
}

#map .js-map.js-map-perspective.js-map-down {
  transform: perspective(300px) rotateX(20deg) rotateZ(180deg);
}

#map .js-map.js-map-perspective.js-map-left {
  transform: perspective(300px) rotateX(20deg) rotateZ(-90deg);
}

#map .js-map.js-map-perspective.js-map-right {
  transform: perspective(300px) rotateX(20deg) rotateZ(90deg);
}

@keyframes mapPerspectiveAnimation {
  0% {
    transform: perspective(0px) rotateX(0deg);
  }
  25% {
    transform: perspective(300px) rotateX(5deg);
  }
  50% {
    transform: perspective(300px) rotateX(10deg);
  }
  75% {
    transform: perspective(300px) rotateX(15deg);
  }
  100% {
    transform: perspective(300px) rotateX(20deg);
  }
}

#map rect.js-map-resource:hover {
  cursor: pointer;
}

#map .interactive-zone-svg {
  position: relative;
  z-index: 10;
}

.interactive-zone.highlighted .circle-default circle,
.interactive-zone.highlighted .circle-default path,
.interactive-zone.selected .circle-default circle,
.interactive-zone.selected .circle-default rect,
.interactive-zone.selected .circle-default path {
  cursor: pointer;
  stroke-width: 5;
  stroke: var(--map-spot-selected-stroke) !important;
}

#map .interactive-zone.disabled .circle-default circle,
#map .interactive-zone.disabled .circle-default ellipse,
#map .interactive-zone.disabled .circle-default path,
#map .interactive-zone.disabled .circle-default rect,
#map .interactive-zone.disabled .circle-default,
#map .interactive-zone .circle-default circle.disabled,
#map .interactive-zone .circle-default ellipse.disabled,
#map .interactive-zone .circle-default path.disabled {
  fill-opacity: 1;
  fill: var(--map-spot-not-bookeable-unavailable-fill);
  stroke: var(--map-spot-not-bookeable-unavailable-stroke);
}

#map .interactive-zone.bookeable-with-schedules .circle-default circle,
#map .interactive-zone.bookeable-with-schedules .circle-default ellipse,
#map .interactive-zone.bookeable-with-schedules .circle-default path,
#map .interactive-zone.bookeable-with-schedules .circle-default rect,
#map .interactive-zone .circle-default circle.bookeable-with-schedules,
#map .interactive-zone .circle-default ellipse.bookeable-with-schedules,
#map .interactive-zone .circle-default path.bookeable-with-schedules {
  fill-opacity: 1;
  fill: var(--map-spot-bookeable-with-schedules-fill);
  stroke: var(--map-spot-bookeable-with-schedules-stroke);
}

#map .interactive-zone.not-bookeable-with-schedules.disabled:hover .circle-default,
#map .interactive-zone.not-bookeable-with-schedules .circle-default circle,
#map .interactive-zone.not-bookeable-with-schedules .circle-default ellipse,
#map .interactive-zone.not-bookeable-with-schedules .circle-default path,
#map .interactive-zone.not-bookeable-with-schedules .circle-default,
#map .interactive-zone .circle-default circle.not-bookeable-with-schedules,
#map .interactive-zone .circle-default ellipse.not-bookeable-with-schedules,
#map .interactive-zone .circle-default path.not-bookeable-with-schedules,
#map .interactive-zone.not-bookeable-with-schedules .circle-profile-photo  circle {
  fill-opacity: 1;
  fill: var(--map-spot-not-bookeable-fill);
  stroke: var(--map-spot-not-bookeable-stroke);
}

#map .interactive-zone.requires-approval.disabled:hover .circle-default,
#map .interactive-zone.requires-approval .circle-default circle,
#map .interactive-zone.requires-approval .circle-default ellipse,
#map .interactive-zone.requires-approval .circle-default path,
#map .interactive-zone .circle-default circle.requires-approval,
#map .interactive-zone .circle-default ellipse.requires-approval,
#map .interactive-zone .circle-default path.requires-approval {
  fill-opacity: 1;
  fill: var(--map-spot-requires-approval-fill);
  stroke: var(--map-spot-requires-approval-stroke);
}

#map .interactive-zone.notAssigned .circle-default circle,
#map .interactive-zone.notAssigned .circle-default ellipse,
#map .interactive-zone.notAssigned .circle-default text,
#map .interactive-zone.notAssigned .circle-default path,
#map .interactive-zone.notAssigned .circle-default rect,
#map .interactive-zone.notAssigned .circle-default {
  fill-opacity: 1;
  fill: var(--map-spot-not-assigned-fill);
  stroke: var(--map-spot-not-assigned-stroke);
}

#map .interactive-zone.disabled .circle-default text {
  fill: rgb(107, 107, 107);
  stroke: #ddd;
}

#map .interactive-zone .circle-default circle,
#map .interactive-zone .circle-default path {
  fill: var(--map-spot-fully-available-fill);
  stroke: var(--map-spot-fully-available-stroke);
  fill-opacity: 1;
}

#map .interactive-zone:hover .circle-default circle,
#map .interactive-zone:hover .circle-default path,
#map .interactive-zone:hover .circle-default ellipse,
#map .interactive-zone:hover .circle-default rect,
#map .interactive-zone:hover .circle-default image {
  cursor: pointer;
  fill-opacity: 1;
}

#map .interactive-zone:hover .circle-default circle,
#map .interactive-zone:hover .circle-default ellipse,
#map .interactive-zone:hover .circle-default path,
#map .interactive-zone:hover .circle-default rect,
#map .interactive-zone:hover .circle-default {
  fill-opacity: 0.1;
}

#map .interactive-zone.disabled:hover .circle-default circle,
#map .interactive-zone.disabled:hover .circle-default ellipse,
#map .interactive-zone:hover .circle-default circle.disabled,
#map .interactive-zone.disabled:hover .circle-default path,
#map .interactive-zone:hover .circle-default path.disabled,
#map .interactive-zone.disabled:hover .circle-default {
  stroke-width: 3;
}

#map .interactive-zone:hover .circle-default text {
  /*@todo chrome96Bug*/
  display: none;
}

#map .interactive-zone.disabled:hover .circle-default text {
  stroke-width: 1;
}

#map .interactive-zone.disabled:hover .circle-default rect {
  stroke-width: 2;
}

#map .interactive-zone.disabled .circle-default {
  fill-opacity: 0.5;
}

#map .interactive-zone:hover .circle-profile-photo {
  cursor: pointer;
}

#map .interactive-zone.show-circle-profile-photo .circle-default circle {
  stroke-width: 7;
}

#map .interactive-zone.show-circle-profile-photo .circle-default circle:first-child {
  display: none;
}

#map .interactive-zone .circle-profile-photo.has-check-in .wrapper-circle {
  fill: transparent;
  stroke: #34d399;
}

#map .interactive-zone.selected .circle-profile-photo  .wrapper-circle {
  fill: transparent;
  stroke: var(--map-spot-selected-stroke);
}

/* Custom Map Edit */
#map .map-edit-element {
  cursor: pointer !important;
}
#map .map-edit-element-selected,
#map .map-edit-element-selected .wrapper-circle,
#map .map-edit-element-selected path {
  fill: #2563eb;
}

#map .non-matching-office-active > * {
  fill: #dc2626;
}

#map .non-matching-floor-active > * {
  fill: #7e22ce;
}

#map .has-nfc-active > * {
  fill: #f59e0b;
}

#hidden-arrow {
  visibility: hidden;
}

#arrow,
#arrow::before {
  position: absolute;
  width: 40px;
  height: 40px;
  background: inherit;
  border: inherit;
  z-index: -99;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

&[data-popper-placement^='top'] > #arrow {
  bottom: -4px;
}

&[data-popper-placement^='bottom'] > #arrow {
  top: -4px;
}

&[data-popper-placement^='left'] > #arrow {
  right: -4px;
}

&[data-popper-placement^='right'] > #arrow {
  left: 14px;
}
