/* Timepicker Styles */
.tailwind-timepicker-wrapper {
  display: flex;
}

.tailwind-timepicker {
  border: 0.5px solid #e2e8f0 !important;
  border-radius: 4px;
  width: 145px;
  @apply shadow-xl;
}

.tailwind-timepicker .react-datepicker__time-container {
  width: 100%;
  float: left;
}

.react-datepicker-wrapper .react-datepicker__input-container > input:focus {
  outline-offset: 0px !important;
}

.tailwind-timepicker .react-datepicker__header {
  display: none !important;
}

.tailwind-timepicker .react-datepicker__time-list-item--selected {
  background-color: #2684ff !important;
  color: #ffffff !important;
  font-weight: normal !important;
}

.tailwind-timepicker .react-datepicker__time {
  border-radius: 0.3rem !important;
}

.tailwind-timepicker .react-datepicker__time-box {
  border-radius: 0.3rem !important;
}

.tailwind-timepicker .react-datepicker__time-list-item {
  height: 40px !important;
  font-size: 16px !important;
  @apply flex items-center;
}

.tailwind-timepicker .react-datepicker__time-list-item:hover {
  background-color: #deebff !important;
  color: hsl(0, 0%, 20%) !important;
}

.tailwind-timepicker input {
  --tw-ring-color: transparent !important;
}

/* DatePicker Styles */

.tailwind-datepicker {
  border: none !important;
  border-radius: 4px;
  width: 325px;
  @apply shadow-xl;
}

.react-datepicker__input-container input {
  border-color: #e2e8f0 !important;
  margin-top: 0.25rem;
  font-size: 16px;
  padding: 0.5rem 0.75rem;
  height: 42px !important;
}

.tailwind-datepicker .tailwind-datepicker .react-datepicker__month-container {
  float: none;
  @apply flex flex-col;
}

.tailwind-datepicker .react-datepicker__header {
  @apply flex flex-col items-center justify-center;
  background: none !important;
}

.tailwind-datepicker .react-datepicker__navigation {
  top: 16px !important;
}

.tailwind-datepicker .react-datepicker__header .react-datepicker__current-month {
  @apply my-2 text-xl text-secondary-bg;
}

.tailwind-datepicker .react-datepicker__triangle {
  display: none;
}

.tailwind-datepicker .react-datepicker__day-names {
  @apply flex w-full justify-around;
}

.tailwind-datepicker .react-datepicker__month {
  @apply my-2 flex flex-col;
}

.tailwind-datepicker .react-datepicker__week {
  @apply flex justify-around;
}

.tailwind-datepicker .react-datepicker__day {
  font-size: 14px;
  @apply flex h-9 w-9 items-center justify-center text-gray-800;
}

.react-datepicker__day:hover {
  border-radius: 9999px !important;
}

.tailwind-datepicker.whole-week .react-datepicker__day:hover {
  background-color: transparent;
}

.tailwind-datepicker.whole-week .react-datepicker__week:hover,
.tailwind-datepicker.whole-week .react-datepicker__week:hover > * {
  @apply bg-primary;
  border-radius: 9999px;
  color: #fff !important;
}

.tailwind-datepicker .react-datepicker__day--selected {
  @apply rounded-full bg-primary text-white;
}

.react-datepicker__day--selected:hover {
  border-radius: 9999px !important;
  @apply bg-primary !important;
}

.tailwind-datepicker .react-datepicker__day.react-datepicker__day--disabled {
  @apply text-gray-500;
  @apply bg-transparent;
}

.tailwind-datepicker .react-datepicker__day--outside-month {
  @apply text-gray-500;
}

/* Fixes https://github.com/Hacker0x01/react-datepicker/issues/2930 */
.tailwind-datepicker .react-datepicker__day--keyboard-selected {
  @apply bg-white;
}

.tailwind-datepicker .react-datepicker__day--in-range,
.tailwind-datepicker .react-datepicker__day--in-selecting-range,
.tailwind-datepicker .react-datepicker__day--selecting-range-start {
  @apply rounded-full !bg-primary text-white;
}

.health-upload-wizard .react-datepicker-popper,
#createOrEditHourRule .react-datepicker-popper
{
  z-index: 11;
}
.health-upload-wizard .react-datepicker__input-container input {
  padding: 0.5rem 1.75rem 0.5rem 2.5rem;
}

/* Inline multiple days version */
.inline-multiple .tailwind-datepicker {
  @apply shadow-none;
}
.inline-multiple .tailwind-datepicker .react-datepicker__day--highlighted,
.inline-multiple .tailwind-datepicker .react-datepicker__day--keyboard-selected.react-datepicker__day--highlighted {
  @apply rounded-full bg-primary text-white;
}

.inline-multiple .tailwind-datepicker .react-datepicker__day--selected,
.inline-multiple .tailwind-datepicker .react-datepicker__day--keyboard-selected {
  @apply rounded-full bg-gray-50 text-gray-800;
}
