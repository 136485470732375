.ReactCrop {
    display: block;
    height: auto;
    margin: auto;
    max-width: fit-content
}
.ReactCrop__image {
    display: block;
    margin: auto;
    max-width: 100%;
    object-fit: scale-down;
    max-height: calc(100vh - 250px);
}
