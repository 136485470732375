/* General */
.markdown-body {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1;
  color: #333;
  padding: 1rem;
}

/* Headings */
.markdown-body h1,
.markdown-body h2,
.markdown-body h3,
.markdown-body h4,
.markdown-body h5,
.markdown-body h6 {
  font-weight: 600;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #eaecef;
}

.markdown-body h1:first-child,
.markdown-body h2:first-child,
.markdown-body h3:first-child,
.markdown-body h4:first-child,
.markdown-body h5:first-child,
.markdown-body h6:first-child {
  margin-top: 0;
  border-top: 0;
}

.markdown-body h1 {
  font-size: 2.5rem;
}
.markdown-body h2 {
  font-size: 2rem;
}
.markdown-body h3 {
  font-size: 1.75rem;
}
.markdown-body h4 {
  font-size: 1.5rem;
}
.markdown-body h5 {
  font-size: 1.25rem;
}
.markdown-body h6 {
  font-size: 1rem;
}

/* Paragraphs */
.markdown-body p {
  margin-top: 0;
  margin-bottom: 1rem;
}

/* Links */
.markdown-body a {
  color: #0366d6;
  text-decoration: none;
}

.markdown-body a:hover {
  text-decoration: underline;
}

/* Code blocks and inline code */
.markdown-body code {
  background-color: #f6f8fa;
  border-radius: 3px;
  font-size: 85%;
  padding: 0.2em 0.4em;
}

.markdown-body pre code {
  background-color: transparent;
  padding: 0;
}

.markdown-body pre {
  background-color: #f6f8fa;
  padding: 1rem;
  overflow: auto;
  border-radius: 3px;
  margin-bottom: 1rem;
}

/* Tables */
.markdown-body table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.markdown-body th,
.markdown-body td {
  border: 1px solid #dfe2e5;
  padding: 0.6em 1em;
}

.markdown-body th {
  background-color: #f6f8fa;
  text-align: left;
}

/* Lists */
.markdown-body ul,
.markdown-body ol {
  padding-left: 2rem;
  margin-top: 0;
  margin-bottom: 1rem;
  list-style: disc;
}

.markdown-body ul li,
.markdown-body ol li {
  margin-bottom: 0.5rem;
}

/* Blockquotes */
.markdown-body blockquote {
  padding: 0.5rem 1rem;
  margin: 0;
  border-left: 0.25em solid #dfe2e5;
  color: #6a737d;
  background-color: #f6f8fa;
  border-radius: 3px;
}

/* Horizontal Rules */
.markdown-body hr {
  border: 0;
  border-top: 1px solid #eaecef;
  margin: 2rem 0;
}

/* Images */
.markdown-body img {
  max-width: 100%;
  height: auto;
}

/* Task Lists */
.markdown-body input[type="checkbox"] {
  margin: 0 0.2em 0.25em -1.6em;
  vertical-align: middle;
  pointer-events: none;
}

/* Highlighted Text */
.markdown-body mark {
  background-color: #ff0;
  color: #000;
}

/* Definition Lists */
.markdown-body dl {
  padding: 0;
}

.markdown-body dt {
  font-weight: bold;
}

.markdown-body dd {
  margin: 0 0 1rem;
  padding: 0 1rem;
}

/* Footnotes */
.markdown-body sup {
  font-size: smaller;
  vertical-align: super;
}

/* Abbreviations */
.markdown-body abbr[title] {
  border-bottom: 1px dotted;
  cursor: help;
  text-decoration: none;
}

/* KBD (keyboard input) */
.markdown-body kbd {
  background-color: #eee;
  border-radius: 3px;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 #ccc, 0 -1px 0 #fff;
  color: #333;
  display: inline-block;
  font-size: 0.85em;
  line-height: 1.4;
  padding: 0.2em 0.4em;
  white-space: nowrap;
}

/* Figures */
.markdown-body figure {
  margin: 1rem 0;
}

.markdown-body figcaption {
  font-size: 0.875em;
  color: #6a737d;
  text-align: center;
}

/* Custom Containers */
.markdown-body .custom-container {
  border: 1px solid #dfe2e5;
  padding: 1rem;
  border-radius: 3px;
  margin-bottom: 1rem;
}

.markdown-body .custom-container.note {
  background-color: #e7f3fe;
  border-color: #c8e1ff;
}

.markdown-body .custom-container.warning {
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.markdown-body .custom-container.danger {
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

/* Details and Summary */
.markdown-body details {
  background-color: #f6f8fa;
  border: 1px solid #dfe2e5;
  border-radius: 3px;
  margin-bottom: 1rem;
  padding: 0.5rem;
}

.markdown-body summary {
  font-weight: bold;
  cursor: pointer;
}
