/* Label */

.responsive-label label {
  bottom: calc(50% - 2px);
  left: 1px;
  transform: translate(0, 50%) scale(1);
  transform-origin: top left;
  font-size: 1rem;
  line-height: 1.25rem;
}
.responsive-label label {
  @apply sm:translate-y-0 sm:translate-x-0 !important;
}
.responsive-label .hidden-on-mobile {
  @apply hidden sm:block !important;
}
.responsive-label:focus-within label {
  transform: translate(0.25rem, calc(-50% - 2px)) scale(0.75);
}
.responsive-label label.filled {
  transform: translate(0.25rem, calc(-50% - 2px)) scale(0.75);
}
